import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoBack, LazySvgoBell, LazySvgoCard, LazySvgoCart, LazySvgoCatalog, LazySvgoChart, LazySvgoCheck, LazySvgoChevronDown, LazySvgoChevronLeft, LazySvgoChevronRight, LazySvgoChevronUp, LazySvgoCopy, LazySvgoCross, LazySvgoDefaultBanner, LazySvgoDeliveryBoxberry, LazySvgoDeliveryEms, LazySvgoDeliveryExpressMkad, LazySvgoDeliveryPinBoxberrySelected, LazySvgoDeliveryPinBoxberry, LazySvgoDeliveryPinSdekSelected, LazySvgoDeliveryPinSdek, LazySvgoDeliveryPinShopSelected, LazySvgoDeliveryPinShop, LazySvgoDeliveryRussianPost, LazySvgoDeliverySdek, LazySvgoDeliveryShop, LazySvgoDialog, LazySvgoDiscount, LazySvgoDone, LazySvgoEdit, LazySvgoEye, LazySvgoFavorites, LazySvgoFilter, LazySvgoHInCircle, LazySvgoHistory, LazySvgoHome, LazySvgoLogin, LazySvgoLogoH, LazySvgoLogo, LazySvgoMeetball, LazySvgoMenu, LazySvgoNoPhotoBig, LazySvgoNoPhotoSmall, LazySvgoNoPhoto, LazySvgoNovaLogo, LazySvgoPaymentSystemMasterCard, LazySvgoPaymentSystemMir, LazySvgoPaymentSystemVisa, LazySvgoPhone, LazySvgoPhoto, LazySvgoPin, LazySvgoPlusIcon, LazySvgoProfile, LazySvgoPuzzle, LazySvgoQuestion, LazySvgoRefresh, LazySvgoReview, LazySvgoSearch, LazySvgoShare, LazySvgoSocialInst, LazySvgoSocialTiktok, LazySvgoSocialVk, LazySvgoSocialYoutube, LazySvgoSpecialOffers, LazySvgoStarEmpty, LazySvgoStar, LazySvgoTrashBin, LazySvgoVideo, LazyPrimeCheckbox, LazyPrimeDropdown, LazyPrimeSlider } from '#components'
const lazyGlobalComponents = [
  ["SvgoBack", LazySvgoBack],
["SvgoBell", LazySvgoBell],
["SvgoCard", LazySvgoCard],
["SvgoCart", LazySvgoCart],
["SvgoCatalog", LazySvgoCatalog],
["SvgoChart", LazySvgoChart],
["SvgoCheck", LazySvgoCheck],
["SvgoChevronDown", LazySvgoChevronDown],
["SvgoChevronLeft", LazySvgoChevronLeft],
["SvgoChevronRight", LazySvgoChevronRight],
["SvgoChevronUp", LazySvgoChevronUp],
["SvgoCopy", LazySvgoCopy],
["SvgoCross", LazySvgoCross],
["SvgoDefaultBanner", LazySvgoDefaultBanner],
["SvgoDeliveryBoxberry", LazySvgoDeliveryBoxberry],
["SvgoDeliveryEms", LazySvgoDeliveryEms],
["SvgoDeliveryExpressMkad", LazySvgoDeliveryExpressMkad],
["SvgoDeliveryPinBoxberrySelected", LazySvgoDeliveryPinBoxberrySelected],
["SvgoDeliveryPinBoxberry", LazySvgoDeliveryPinBoxberry],
["SvgoDeliveryPinSdekSelected", LazySvgoDeliveryPinSdekSelected],
["SvgoDeliveryPinSdek", LazySvgoDeliveryPinSdek],
["SvgoDeliveryPinShopSelected", LazySvgoDeliveryPinShopSelected],
["SvgoDeliveryPinShop", LazySvgoDeliveryPinShop],
["SvgoDeliveryRussianPost", LazySvgoDeliveryRussianPost],
["SvgoDeliverySdek", LazySvgoDeliverySdek],
["SvgoDeliveryShop", LazySvgoDeliveryShop],
["SvgoDialog", LazySvgoDialog],
["SvgoDiscount", LazySvgoDiscount],
["SvgoDone", LazySvgoDone],
["SvgoEdit", LazySvgoEdit],
["SvgoEye", LazySvgoEye],
["SvgoFavorites", LazySvgoFavorites],
["SvgoFilter", LazySvgoFilter],
["SvgoHInCircle", LazySvgoHInCircle],
["SvgoHistory", LazySvgoHistory],
["SvgoHome", LazySvgoHome],
["SvgoLogin", LazySvgoLogin],
["SvgoLogoH", LazySvgoLogoH],
["SvgoLogo", LazySvgoLogo],
["SvgoMeetball", LazySvgoMeetball],
["SvgoMenu", LazySvgoMenu],
["SvgoNoPhotoBig", LazySvgoNoPhotoBig],
["SvgoNoPhotoSmall", LazySvgoNoPhotoSmall],
["SvgoNoPhoto", LazySvgoNoPhoto],
["SvgoNovaLogo", LazySvgoNovaLogo],
["SvgoPaymentSystemMasterCard", LazySvgoPaymentSystemMasterCard],
["SvgoPaymentSystemMir", LazySvgoPaymentSystemMir],
["SvgoPaymentSystemVisa", LazySvgoPaymentSystemVisa],
["SvgoPhone", LazySvgoPhone],
["SvgoPhoto", LazySvgoPhoto],
["SvgoPin", LazySvgoPin],
["SvgoPlusIcon", LazySvgoPlusIcon],
["SvgoProfile", LazySvgoProfile],
["SvgoPuzzle", LazySvgoPuzzle],
["SvgoQuestion", LazySvgoQuestion],
["SvgoRefresh", LazySvgoRefresh],
["SvgoReview", LazySvgoReview],
["SvgoSearch", LazySvgoSearch],
["SvgoShare", LazySvgoShare],
["SvgoSocialInst", LazySvgoSocialInst],
["SvgoSocialTiktok", LazySvgoSocialTiktok],
["SvgoSocialVk", LazySvgoSocialVk],
["SvgoSocialYoutube", LazySvgoSocialYoutube],
["SvgoSpecialOffers", LazySvgoSpecialOffers],
["SvgoStarEmpty", LazySvgoStarEmpty],
["SvgoStar", LazySvgoStar],
["SvgoTrashBin", LazySvgoTrashBin],
["SvgoVideo", LazySvgoVideo],
["PrimeCheckbox", LazyPrimeCheckbox],
["PrimeDropdown", LazyPrimeDropdown],
["PrimeSlider", LazyPrimeSlider],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
