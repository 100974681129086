import { default as _91slug_93cdf2dv6FbPMeta } from "/app/pages/blog/[slug].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as _91name_93FuYAqwYxehMeta } from "/app/pages/brands/[name].vue?macro=true";
import { default as indexooJswcUi1vMeta } from "/app/pages/brands/index.vue?macro=true";
import { default as indexAacQV8oPDMMeta } from "/app/pages/cart/index.vue?macro=true";
import { default as indexhypb6UNn8QMeta } from "/app/pages/catalog/[...slug]/index.vue?macro=true";
import { default as indexvJKqjSbZLzMeta } from "/app/pages/catalog/index.vue?macro=true";
import { default as index7ZtduOIoVXMeta } from "/app/pages/checkout/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91slug_93wOytK7iaaUMeta } from "/app/pages/info/[slug].vue?macro=true";
import { default as indexTRToSO5OIaMeta } from "/app/pages/likeable/index.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as maintenanceM1L3mPagESMeta } from "/app/pages/maintenance.vue?macro=true";
import { default as page_45not_45found8L0MhHNDjFMeta } from "/app/pages/page-not-found.vue?macro=true";
import { default as addressesO3etS3Y6CAMeta } from "/app/pages/profile/index/addresses.vue?macro=true";
import { default as favorites55zJtsFVcWMeta } from "/app/pages/profile/index/favorites.vue?macro=true";
import { default as infow8TnrWkOo7Meta } from "/app/pages/profile/index/info.vue?macro=true";
import { default as ordersmWVb8rqITsMeta } from "/app/pages/profile/index/orders.vue?macro=true";
import { default as returnsZ6PAdNW3VzMeta } from "/app/pages/profile/index/returns.vue?macro=true";
import { default as reviews38SX2xFnk4Meta } from "/app/pages/profile/index/reviews.vue?macro=true";
import { default as indexaeouN7Kzh7Meta } from "/app/pages/profile/index.vue?macro=true";
import { default as indexHVbCWNFgttMeta } from "/app/pages/sale/index.vue?macro=true";
import { default as indexP0q8KYV1WMMeta } from "/app/pages/search/index.vue?macro=true";
import { default as indexBV1zPX3VR7Meta } from "/app/pages/sitemap/index.vue?macro=true";
import { default as _91slug_9387KzJmF8rIMeta } from "/app/pages/special-offers/[slug].vue?macro=true";
import { default as indexSKut4QXfKYMeta } from "/app/pages/special-offers/index.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-name",
    path: "/brands/:name()",
    component: () => import("/app/pages/brands/[name].vue").then(m => m.default || m)
  },
  {
    name: "brands",
    path: "/brands",
    meta: indexooJswcUi1vMeta || {},
    component: () => import("/app/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    meta: indexAacQV8oPDMMeta || {},
    component: () => import("/app/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-slug",
    path: "/catalog/:slug(.*)*",
    component: () => import("/app/pages/catalog/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog",
    path: "/catalog",
    meta: indexvJKqjSbZLzMeta || {},
    component: () => import("/app/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info-slug",
    path: "/info/:slug()",
    component: () => import("/app/pages/info/[slug].vue").then(m => m.default || m)
  },
  {
    name: "likeable",
    path: "/likeable",
    component: () => import("/app/pages/likeable/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceM1L3mPagESMeta || {},
    component: () => import("/app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "page-not-found",
    path: "/page-not-found",
    component: () => import("/app/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/app/pages/profile/index.vue").then(m => m.default || m),
    children: [
  {
    name: "profile-index-addresses",
    path: "addresses",
    component: () => import("/app/pages/profile/index/addresses.vue").then(m => m.default || m)
  },
  {
    name: "profile-index-favorites",
    path: "favorites",
    component: () => import("/app/pages/profile/index/favorites.vue").then(m => m.default || m)
  },
  {
    name: "profile-index-info",
    path: "info",
    component: () => import("/app/pages/profile/index/info.vue").then(m => m.default || m)
  },
  {
    name: "profile-index-orders",
    path: "orders",
    component: () => import("/app/pages/profile/index/orders.vue").then(m => m.default || m)
  },
  {
    name: "profile-index-returns",
    path: "returns",
    component: () => import("/app/pages/profile/index/returns.vue").then(m => m.default || m)
  },
  {
    name: "profile-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/profile/index/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "sale",
    path: "/sale",
    component: () => import("/app/pages/sale/index.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    meta: indexP0q8KYV1WMMeta || {},
    component: () => import("/app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "sitemap",
    path: "/sitemap",
    meta: indexBV1zPX3VR7Meta || {},
    component: () => import("/app/pages/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: "special-offers-slug",
    path: "/special-offers/:slug()",
    component: () => import("/app/pages/special-offers/[slug].vue").then(m => m.default || m)
  },
  {
    name: "special-offers",
    path: "/special-offers",
    meta: indexSKut4QXfKYMeta || {},
    component: () => import("/app/pages/special-offers/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/news/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/articles/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  }
]