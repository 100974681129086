<script setup lang="ts">
const error = useError();
const is404 = computed(() => error.value?.statusCode === 404);

if (is404.value) {
  await navigateTo('/page-not-found', { replace: true });
}
</script>

<template>
  <div>
    <div class="flex items-center justify-center h-screen">
      <div class="flex flex-col gap-6 max-w-xl py-10 px-4 text-center">
        <h1 class="text-5xl">{{ error?.statusCode }}</h1>
        <h2>Произошла ошибка</h2>

        <p>{{ error?.message }}</p>

        <p>
          Попробуйте перейти на
          <AppLink
            class="text-label-violet"
            to="/"
          >
            Главную страницу
          </AppLink>
          или в
          <AppLink
            class="text-label-violet"
            to="/catalog"
          >
            Каталог товаров
          </AppLink>
          и попробовать еще раз. Если ошибка повторяется, пожалуйста, сообщите нам об этом
          <AppLink
            class="text-label-violet"
            to="/info/kontakty"
          >
            любым удобным вам способом
          </AppLink>
          . Спасибо!
        </p>
      </div>
    </div>
  </div>
</template>
