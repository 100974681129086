<script lang="ts" setup>
import type { PropType } from 'vue';

const props = defineProps({
  to: {
    type: String as PropType<string | null | undefined>,
    default: '',
  },
  target: {
    type: String,
    default: '_self',
  },
});

const notAllowedNuxtNavigations = [
  'profile',
  //
  'payments',
  'login',
];

const isNotANuxtLink = computed(() =>
  notAllowedNuxtNavigations.some((item) => (props.to ? props.to.includes(item) : false) && props.to?.startsWith('/')),
);
</script>

<template>
  <a
    v-if="to && isNotANuxtLink"
    :href="to"
    :target="target"
  >
    <slot />
  </a>

  <NuxtLink
    v-else-if="to"
    :target="target"
    :to="to"
  >
    <slot />
  </NuxtLink>

  <span v-else>
    <slot />
  </span>
</template>
